import React from 'react';

const AboutUs = () => {
  return (
    <section id="about" className="py-12 bg-background text-dark">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6 text-graydark">About Us</h2>
        <p className="text-lg mb-6">
          Nana Fibre is dedicated to sustainability and innovation. We offer an innovative solution by repurposing pineapple waste into sustainable and eco-friendly yarn. Our mission is to reduce agricultural waste and provide an environmentally conscious alternative to traditional textile fibers.
        </p>
        <p className="text-lg">
          Our process starts with collecting pineapple peels and leaves, which are typically discarded as waste. These materials are then processed and transformed into high-quality yarn. This not only helps in reducing waste but also creates a valuable resource that can be used in the textile industry.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
