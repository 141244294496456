import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import AboutUs from './components/AboutUs';
import Process from './components/Process';
import Gallery from './components/Gallery';
import SignUp from './components/SignUp';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <AboutUs />
      <Process />
      <Gallery />
      <SignUp />
      <Footer />
    </div>
  );
}

export default App;
