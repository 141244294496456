import React from "react";

const SignUp = () => {
  return (
    <section id="signup" className="py-12 bg-primary text-dark">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6 text-dark">
          Be the First to Get Our Yarn
        </h2>
        <p className="text-lg mb-6">
          Sign up to receive updates and be the first to know when our yarn is
          ready. By joining our community, you'll be supporting sustainable and
          eco-friendly practices in the textile industry.
        </p>
        <form className="max-w-lg mx-auto">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full p-3 rounded mb-4 text-dark shadow-lg"
          />
          <button className="w-full bg-dark text-primary p-3 rounded font-bold hover:bg-graydark shadow-lg">
            Sign Up
          </button>
        </form>
      </div>
    </section>
  );
};

export default SignUp;
