import React from 'react';
import logo from '../assets/nanafibre-logo.png'; // Adjust the path according to your file structure

const Header = () => {
  return (
    <header className="bg-background shadow-md p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Nana Fibre Logo" className="h-16 mr-3" />
          <div className="text-2xl font-bold text-dark hidden md:block">Nana Fibre</div>
        </div>
        <nav className="text-dark">
          <a href="#about" className="mx-2 hover:text-primary">About Us</a>
          <a href="#process" className="mx-2 hover:text-primary">Process</a>
          <a href="#gallery" className="mx-2 hover:text-primary">Gallery</a>
          <a href="#signup" className="mx-2 hover:text-primary">Sign Up</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
