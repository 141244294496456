import React from "react";
import fibres from '../assets/fibres.jpg'
import leavespile from '../assets/leavespile.jpg'
import yarn from '../assets/yarn.jpg'

const Process = () => {
  return (
    <section id="process" className="py-12 bg-graydark text-background">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Our Process</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <img
              src={leavespile}
              alt="Step 1"
              className="w-full mb-4 rounded shadow-lg"
            />
            <h3 className="text-xl font-bold">Step 1: Collection</h3>
            <p>
              We partner with local pineapple farms to collect the peels and
              leaves that are usually discarded as waste. This helps reduce the
              environmental impact of pineapple farming.
            </p>
          </div>
          <div>
            <img
              src={fibres}
              alt="Step 2"
              className="w-full mb-4 rounded shadow-lg"
            />
            <h3 className="text-xl font-bold">Step 2: Processing</h3>
            <p>
              The collected waste is processed to extract the fibers using one
              of three methods: mechanical degumming, enzymatic degumming, or
              bio-degumming. Each method has its own benefits and produces
              high-quality fibers.
            </p>
          </div>
          <div>
            <img
              src={yarn}
              alt="Step 3"
              className="w-full mb-4 rounded shadow-lg"
            />
            <h3 className="text-xl font-bold">Step 3: Yarn Production</h3>
            <p>
              Once the fibers are extracted and cleaned, they are spun into
              yarn. This yarn is durable, lightweight, and eco-friendly, making
              it perfect for a wide range of textile products.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
