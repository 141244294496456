import React from "react";
import heroimage from "../assets/heroimage.jpg";

const Banner = () => {
  return (
    <section
      className="bg-cover bg-center min-h-[calc(100vh-4rem)] relative flex items-center justify-center"
      style={{
        backgroundImage: `url('${heroimage}')`,
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="container mx-auto h-full flex items-center justify-center text-center relative">
        <div className="bg-dark bg-opacity-75 p-8 rounded-lg">
          <h1 className="text-4xl font-bold mb-4 text-primary">
            Welcome to Nana Fibre
          </h1>
          <p className="text-xl text-primary">
            Transforming Pineapple Waste into Sustainable Yarn
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
