import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-primary py-4">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 Nana Fibre. All rights reserved.</p>
        <p>
          Follow us on social media:
          <a
            href="https://www.linkedin.com/company/nana-fibre"
            className="mx-2 hover:text-graydark"
          >
            Linkedin
          </a>
          <a
            href="https://www.linkedin.com/company/nana-fibre"
            className="mx-2 hover:text-graydark"
          >
            Twitter
          </a>
          <a
            href="https://www.linkedin.com/company/nana-fibre"
            className="mx-2 hover:text-graydark"
          >
            Instagram
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
