import React from "react";
import dryingleaves from '../assets/dryingleaves.jpg';
import peopleworking from '../assets/peopleworking.jpg';
import yarnprocessing from '../assets/yarnprocessing.jpg';

const Gallery = () => {
  return (
    <section id="gallery" className="py-12 bg-background text-dark">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6 text-graydark">Gallery</h2>
        <p className="text-lg mb-6">
          Explore our gallery to see the various stages of our process and the
          beautiful products made from Nana Fibre yarn.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="relative h-64 w-full overflow-hidden rounded shadow-lg">
            <img
              src={dryingleaves}
              alt="Gallery 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="relative h-64 w-full overflow-hidden rounded shadow-lg">
            <img
              src={peopleworking}
              alt="Gallery 2"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="relative h-64 w-full overflow-hidden rounded shadow-lg">
            <img
              src={yarnprocessing}
              alt="Gallery 3"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
